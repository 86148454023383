export const jsonDataProhibit = {
  code: 200,
  message: 'OK',
  data: {
    limiters: [
      {
        id: '6555b9024f05a179d1741dfa',
        limit_type: 1,
        url: 'https://www.4chan.org/index.php',
      },
      {
        id: '6555b9024f05a179d1741df9',
        limit_type: 1,
        url: 'https://www.redtube.com/',
      },
      {
        id: '6555b9024f05a179d1741df8',
        limit_type: 1,
        url: 'https://www.pornhub.com/',
      },
      {
        id: '6555b9024f05a179d1741df7',
        limit_type: 1,
        url: 'https://www.hackthissite.org/',
      },
      {
        id: '6555b9024f05a179d1741df6',
        limit_type: 1,
        url: 'https://www.betonline.ag/',
      },
    ],
    total: 5,
  },
};

export const jsonDataAccess = {
  code: 200,
  message: 'OK',
  data: {
    limiters: [
      {
        id: '6555b9014f05a179d1741df0',
        limit_type: 2,
        url: 'https://www.omegle.com/',
      },
      {
        id: '6555b9014f05a179d1741def',
        limit_type: 2,
        url: 'https://freespin.com/',
      },
      {
        id: '6555b9014f05a179d1741dee',
        limit_type: 2,
        url: 'https://www.stormfront.org/',
      },
      {
        id: '6555b9014f05a179d1741ded',
        limit_type: 2,
        url: 'https://www.liveleak.com/',
      },
      {
        id: '6555b9014f05a179d1741dec',
        limit_type: 2,
        url: 'https://tinder.com/',
      },
    ],
    total: 5,
  },
};

export const jsonDataHistory = {
  code: 200,
  message: 'OK',
  data: {
    history_records: [
      {
        browser_name: 'Samsung Internet Browser',
        date: 1700178899,
        limit_type: 2,
        url: 'https://freespin.com/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1699979399,
        limit_type: 2,
        url: 'https://www.stormfront.org/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1699300199,
        limit_type: 1,
        url: 'https://www.pornhub.com/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1699210200,
        limit_type: 1,
        url: 'https://www.betonline.ag/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1699499459,
        limit_type: 2,
        url: 'https://tinder.com/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1698766200,
        limit_type: 2,
        url: 'https://www.omegle.com/',
      },
      {
        browser_name: 'Samsung Internet Browser',
        date: 1697747699,
        limit_type: 2,
        url: 'https://www.liveleak.com/',
      },
    ],
    total: 7,
    update_time: 1700116734,
  },
};
